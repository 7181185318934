<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="typeId"
                :items="vacancyTypesDropdown"
                :menu-props="{ maxHeight: '400' }"
                label="Тип"
                :rules="[rules.required, rules.enumRequired]"
                dense
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="isActive"
                label="Активен"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              md="12"
              cols="24"
            >
              <v-tabs
                v-model="tab"
                show-arrows
              >
                <v-tab v-for="(item, index) in translations" :key="index">
                  <span>{{ item.language }}</span>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, index) in translations" :key="index">
                  <v-col
                    cols="24"
                    md="12"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Наименование"
                      dense
                      outlined
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="24"
                    md="12"
                  >
                    Описание
                    <tip-tap
                      :rules="[rules.required]"
                      v-model="item.description"
                    ></tip-tap>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import DatePicker from '@/components/date-picker'
import TipTap from '@/components/tip-tap'
import { rules } from '@/helpers/validation'

export default {
  name: 'VacancyForm',
  props: ['save', 'show', 'cancellation', 'label'],
  components: { TipTap, DatePicker },
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    tab: null,
    rules,
  }),
  computed: {
    ...mapFormHandlers('vacancy/form', [
      'translations',
      'typeId',
      'isActive',
    ]),
    ...mapGetters('dictionary', { vacancyTypesDropdown: 'vacancyTypesDropdown' })
  },
  async mounted() {
    await this.fetchVacancyTypes()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('vacancy/form', ['resetForm']),
    ...mapActions('dictionary', {
      fetchVacancyTypes: 'fetchVacancyTypes'
    }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm();
      }
    },
  },
}
</script>
