<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.VacancyCreate')"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="contacts"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>
      <template #[`item.type`]="{item}">
        {{ item.type }}
      </template>
      <template #[`item.isActive`]="{item}">
        <v-chip
          small
          :color="getActivityColor(item.isActive)"
          :class="`${getActivityColor(item.isActive)}--text`"
        >
          {{ getActivityString(item.isActive) }}
        </v-chip>
      </template>
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.VacancyUpdate')"
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.VacancyDelete')"
                v-bind="attrs"
                v-on="on"
                @click="deletePopupShow(item.id, item.name)"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed="deleteContact" :label="deleteLabel" ref="deletePopup" />
    <vacancy-form :label="'Редактирование вакансии'" :cancellation="updateDialogCancel" :show="updateDialog"
                  :save="updateContact"></vacancy-form>
    <vacancy-form :label="'Создание вакансии'" :cancellation="createDialogCancel" :show="createDialog"
                  :save="addContact"></vacancy-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog'
import VacancyForm from './vacancy-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { hasPermission } from '@/helpers'

export default {
  components: { DeleteDialog, VacancyForm },
  name: 'VacancyList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('vacancy', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
      'filters.vacancyTypeId',
    ]),
    ...mapState('vacancy', {
      contacts: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Тип', value: 'type',  sortable: false },
        { text: 'Активен', value: 'isActive' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Вакансии'
    this.fetchContacts()
  },
  methods: {
    ...mapActions('vacancy', {
      fetchContacts: 'fetchList',
      deleteContact: 'delete',
    }),
    ...mapActions('vacancy/form', ['create', 'resetForm', 'fetchById', 'update']),
    hasNotPermission(val){
      return !hasPermission(val);
    },
    deletePopupShow(id, name) {
      this.deleteLabel = 'Вакансия ' + name + ' будет удалена.'
      this.$refs.deletePopup.open(id)
    },
    async addContact() {
      await this.create()
      this.createDialog = false
    },
    async updateContact() {
      await this.update()
      this.updateDialog = false
    },
    getActivityString(isBanner) {
      return isBanner ? 'Да' : 'Нет'
    },
    getActivityColor(isBanner) {
      return isBanner ? 'success' : 'error'
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
